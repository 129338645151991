"use client";
import React, { FC, useEffect, useState } from "react";
import { Toast } from "antd-mobile";
import classnames from "classnames";
import { collectioncar, cancelcollectionca } from "./api";
import Login from "@/components/Login";
import style from "./index.module.css";
import useUser from "@/hooks/useUser";

interface ICollectStarProps {
  isRecommend?: number;
  unifiednumber: string;
  ucarId: number;
  className?: string;
  isBigBlack?: boolean;
  item?: any;
  onClick?: (item: any) => void;
}

const CollectStar: FC<ICollectStarProps> = ({
  isRecommend,
  unifiednumber,
  ucarId,
  className,
  isBigBlack,
  onClick,
  item,
}) => {
  const { user } = useUser();

  const [isRecommendCar, setIsRecommendCar] = useState(isRecommend === 1);
  useEffect(() => {
    setIsRecommendCar(isRecommend === 1);
  }, [isRecommend]);

  const handStarClick = () => {
    if (onClick)
      onClick({
        ...item,
        isRecommend: isRecommendCar ? 1 : 0,
      });
    if (isRecommendCar) {
      cancelcollectionca({
        unifiednumber,
        ucarid: ucarId,
      })
        .then((res) => {
          setIsRecommendCar(false);
          Toast.show({
            content: "取消成功",
          });
        })
        .catch(() => {
          Toast.show({
            content: "取消失败",
          });
        });
    } else {
      collectioncar({
        unifiednumber,
        ucarid: ucarId,
      })
        .then((res) => {
          setIsRecommendCar(true);
          Toast.show({
            content: "收藏成功",
          });
        })
        .catch(() => {
          Toast.show({
            content: "收藏失败",
          });
        });
    }
  };

  const light = isRecommendCar && user?.islogin;
  return (
    <Login onSubmit={handStarClick}>
      <div
        className={classnames(style.star, className)}
        style={{
          backgroundImage: `url(https://static.taocheche.com/MWeb/components/product/${
            light ? "bright_star" : isBigBlack ? "black_star" : "star"
          }.png)`,
        }}
      />
    </Login>
  );
};

export default CollectStar;
