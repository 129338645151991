import fetch from "@/utils/fetch"
export const cancelcollectionca = (body) =>
    fetch("/c-userbusiness-consumer/user/cancelcollectioncar", {
        method: "post",
        body,
    });

export const collectioncar = (body) =>
    fetch("/c-userbusiness-consumer/user/collectioncar", {
        method: "post",
        body,
    });