export const getList = (res) => {
    if (!res || !Object.keys(res)?.length) return
    const data = (res.recCars?.map((item) => ({
        ...item,
        recCarPool: res.recCarPool
    }))) ?? [];
    let data1 = res.recOne ? [{ ...res.recOne, isOne: true }] : []; //第一列

    const data2_item = res.recSerials?.map((item) => {
        return {
            ...item,
            recCarPool: res.recCarPool
        }
    })
    let data2 = [

    ];//第二列
    if (data2_item) {
        data2.push(data2_item)
    }
    let i = 0;

    if (!data1[0] && data2[0]) {
        data1.push({
            index: i,
            ...data[i++]
        });
    }
    if (res.recSerials && res.recSerials?.length < 3) {
        data2 = [
            {
                index: i,
                ...data[i++]
            }
        ];
    }

    if (!data) return

    while (i < data.length) {
        data1.push({
            index: i,
            ...data[i++]
        });
        if (i < data.length) {
            data2.push({
                index: i,
                ...data[i++]
            });
        }
    }
    return [data1, data2]
}

export const getDetailList = (res, response) => {
    const data = res?.uCarBasicInfoList?.dataList
    if (!data) return;
    let data1 = [], //第一列
        data2 = [], //第二列
        i = 0;
    while (i < data.length) {
        data1.push({
            index: i,
            ...data[i++]
        });
        if (i === 3) {
            data2.push(response);
            continue;
        }
        if (i < data.length) {
            data2.push({
                index: i,
                ...data[i++]
            });
        }
    }

    // if (data1.length > data2.length) {
    //     data2.push(data1.pop())
    // }

    return [data1, data2]
}