
import fetch from "@/utils/fetch";

export const getRecommendBasics = (params) => fetch(`/c-carbrand-consumer/recommend/basics`, {
    params
});



// 车系推荐
export const getRecommendSerial = (params) => fetch(`/c-carbrand-consumer/recommend/getRecommendSerial`, {
    params
});

// 推荐车源
export const getSimilarCarList = (params) => fetch(`/c-car-consumer/carlist/getSimilarCarList`, {
    params
});


export const cancelcollectionca = (body) =>
    fetch("/c-userbusiness-consumer/user/cancelcollectioncar", {
        method: "post",
        body,
    });

export const collectioncar = (body) =>
    fetch("/c-userbusiness-consumer/user/collectioncar", {
        method: "post",
        body,
    });



