"use client";
/*
 * @Author: qiang
 * @Date: 2023-11-14 18:49:35
 * @LastEditTime: 2024-01-05 16:26:45
 * @LastEditors: qiang
 * @Description: 为您推荐
 */
import React, { useEffect, useState } from "react";
// import Image from "next/image";
import classNames from "classnames";
import Link from "@/components/Link";
import Image from "@/components/Image";
import useRouter from "@/hooks/useRouter";
import { sourceToString } from "@/hooks/useReport";
import { app_host } from "@/utils/fetch";
import {
  getRecommendBasics,
  getSimilarCarList,
  getRecommendSerial,
} from "./api";
import OrdinaryItem from "./OrdinaryItem";
import MoreCarSeries from "./MoreCarSeries";
import { getDetailList, getList } from "./utils";
import Loading from "./Loading";

import style from "./index.module.css";

interface IRecommendedProps {
  title?: string;
  // 1、首页，2、个人中心，3、收藏，4、我看过的，5、预约成功
  source?: 1 | 2 | 3 | 4 | 5;
  excludeUnifiedNumbers?: string;
  // 数据源
  initList: [ItemProps[], ItemProps[]];
  // 车ID 详情页必传 有这个就是详情页面的调用
  ucarid?: string;
  //  是否需要更多
  isMore?: boolean;
  // 是否需要底部间距
  isSpacing?: boolean;
  size?: number;
  type?: number;
  // 是否需要组件自行发出请求
  isFetch?: boolean;
  pfrom_id: number;
  // 收藏功能
  onCollectStarClick?: (item: any) => void;
  // 卡片的点击
  onClick?: (item: ItemProps) => void;
  // 车系点击
  onSeriesClick?: (item: ItemProps) => void;
  // 更多点击
  onMoreClick?: () => void;
  styles?: Object;
}

const Recommended: React.FC<IRecommendedProps> = ({
  title,
  ucarid,
  isMore = true,
  isSpacing = true,
  onClick,
  onMoreClick,
  onSeriesClick,
  onCollectStarClick,
  initList,
  source,
  size = 40,
  type = 2,
  excludeUnifiedNumbers,
  isFetch,
  pfrom_id,
  styles,
}) => {
  const router = useRouter();
  const [recommendedList, setRecommendedList] = useState<ListProps>(
    initList ?? ([] as any)
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initList) {
      setRecommendedList(initList);
    }
  }, [initList]);

  useEffect(() => {
    if (!source || !isFetch) return;
    setLoading(true);
    if (ucarid) {
      getRecommendSerial().then((response) => {
        getSimilarCarList({
          ucarid,
          size,
          type,
        }).then((res) => {
          setLoading(false);
          const result = getDetailList(res, response);
          if (result) {
            setRecommendedList(result as any);
          }
        });
      });
      return;
    }
    getRecommendBasics({ source, excludeUnifiedNumbers }).then((res) => {
      setLoading(false);
      const result = getList(res);
      if (result) {
        setRecommendedList(result as any);
      }
    });
  }, [isFetch, source, excludeUnifiedNumbers, ucarid, size, type]);

  // console.info("recommendedList", recommendedList);

  console.info("recommendedList", recommendedList);
  if (loading) return <Loading></Loading>;
  if (
    !recommendedList ||
    (!recommendedList[0]?.length && !recommendedList[1]?.length)
  )
    return null;
  return (
    <div
      className={classNames(style.recommended, {
        [style.isSpacing]: isSpacing,
      })}
      style={styles}
    >
      <div className={style.title}>{title || "为您推荐"}</div>
      <div className={style.list}>
        {recommendedList?.map((el, i) => {
          return (
            <div key={i} className={style.colmun}>
              {el.map((item, index) => {
                if (!item) return null;
                if (i == 0 && index == 0 && item.isOne) {
                  return (
                    <Link
                      key={index}
                      href={`/iframe?title=排行榜&url=${item.url}&from=m`}
                    >
                      <Image
                        src={item.imgUrl}
                        alt={item.name}
                        className={style.img}
                        width={344}
                        height={220}
                      />
                    </Link>
                  );
                }
                if (i === 1 && index === 0 && !ucarid && Array.isArray(item)) {
                  return (
                    <div key={index} className={style.item}>
                      <MoreCarSeries
                        list={item as unknown as ItemProps[]}
                        key={index}
                        onSeriesClick={onSeriesClick}
                      />
                    </div>
                  );
                }
                if (i === 1 && index === 1 && ucarid && Array.isArray(item)) {
                  return (
                    <div key={index} className={style.item}>
                      <MoreCarSeries
                        list={item as unknown as ItemProps[]}
                        key={index}
                        onSeriesClick={onSeriesClick}
                      />
                    </div>
                  );
                }
                return (
                  <Link
                    key={index}
                    href={`${app_host}/detail/index.html?ucarId=${item.ucarId}&pfrom_id=${pfrom_id}`}
                    onClick={() => {
                      if (onClick) {
                        onClick(item);
                      }
                    }}
                    className="sdk-exposure-spa"
                    data-exposure-extend={JSON.stringify({
                      tc_extendvalue1: JSON.stringify({
                        title: sourceToString(item?.source),
                        special:
                          item?.recCarPool == 1
                            ? "浏览"
                            : item?.recCarPool == 2
                            ? "收藏"
                            : item?.recCarPool == 3
                            ? "我看过的"
                            : "",
                      }),
                      module: "为您推荐车源",
                      ucar_id: item.ucarId,
                    })}
                  >
                    <div className={style.item}>
                      <OrdinaryItem
                        onCollectStarClick={onCollectStarClick}
                        item={item}
                      />
                    </div>
                  </Link>
                );
              })}
            </div>
          );
        })}
      </div>
      {!ucarid && isMore && (
        <div
          className={style.more}
          onClick={() => {
            if (onMoreClick) {
              onMoreClick();
            }
            router.push("/cars");
          }}
        >
          查看更多
          <span className={style.icon}></span>
        </div>
      )}
    </div>
  );
};

export default Recommended;
